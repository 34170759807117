import { SET_DIPLOMA, SET_FEE_STRUCTURE, SET_LEARNING_PLAN, SET_PROGRAM_DATA, SET_PROGRAM_LIST, SET_SKILL_SCAN, SET_STANDARD_DETAIL , SET_FUNCTIONAL_SKILL, SET_CLEAR_VALUE , SET_THE_CLONE_PROGRAM , UPDATE_SKILL_SCAN, SET_SKILL, SET_KNOWLEDGE, SET_BEHAVIOR, COMPLETE_DATA, SET_REMARK} from "./action";
import { ProgramResponse } from "./types";

const initialState: ProgramResponse = {
  request : {data : ''} ,
  programDetail : null,
  data:[] ,
  complete_data:[],
  function : [],
  skillScan : null,
  learningPlan: null,
  feesStructure : [],
  diploma : [],
  cloneProgram : [],
  StandardDetail : null,
  knowledge : null,
  skills : null,
  behavior : null,
  remark:null
};


const programReducer = (state = initialState, action: any): ProgramResponse => {
  switch (action.type) {
    case COMPLETE_DATA:
      return {...state , complete_data: action.payload};
    case SET_CLEAR_VALUE : 
       return initialState ;
    case SET_PROGRAM_DATA:
      return { ...state, programDetail : action.payload };
    case SET_FEE_STRUCTURE : 
        return {...state ,  feesStructure : action.payload};
    case SET_STANDARD_DETAIL :
        return {...state , StandardDetail : action.payload};
    case SET_LEARNING_PLAN :   
        return {...state , learningPlan:action.payload};
    case SET_PROGRAM_LIST:
      return { ...state, data:action.payload }; 
    case SET_SKILL_SCAN : 
        return {...state , skillScan:action?.payload?.skill}   
    case SET_DIPLOMA : 
        return {...state , diploma:action.payload}   
    case SET_FUNCTIONAL_SKILL : 
        return {...state , diploma:action.payload}
    case SET_THE_CLONE_PROGRAM : 
    return {...state , cloneProgram:action.payload}  
    case SET_SKILL : 
    return {...state , skills:action.payload}  
    case SET_KNOWLEDGE : 
    return {...state , knowledge:action.payload}
    case SET_REMARK : 
    return {...state , remark:action.payload}
    case SET_BEHAVIOR : 
    return {...state , behavior:action.payload}  
    case UPDATE_SKILL_SCAN:
      return {
        ...state,
        skillScan: action.payload,
      };     
    default:
      return state;
  }
};

export default programReducer;
