/* eslint-disable */

export const OTHER_DOC = 'OTHER_DOC';
export const INITIAL_ASSESSMENT = 'INITIAL_ASSESSMENT';
export const COMPLIANCE_DOC = 'COMPLIANCE_DOC';
export const DOCUMENT = 'DOCUMENT'
export const LEARNER = 'LEARNER'
export const FOLDER ='FOLDER'
export const GET_FOLDER ='GET_FOLDER'


export const setDocOther = (Fileupload : any) => ({
    type: OTHER_DOC,
    payload: Fileupload
})

export const setFolder = (Fileupload : any) => ({
    type: FOLDER,
    payload: Fileupload
})

export const getFolder = (Fileupload : any) => ({
    type: GET_FOLDER,
    payload: Fileupload
})

export const setDocInitialAssessment= (Fileupload : any) => ({
    type: INITIAL_ASSESSMENT,
    payload: Fileupload
})

export const setDocCompliance= (Fileupload : any) => ({
    type: COMPLIANCE_DOC,
    payload: Fileupload
})

export const setDocument = (document : any) => ({
    type : DOCUMENT,
    payload : document
})

export const setLearnerId = (learner : any) => ({
    type : LEARNER,
    payload : learner
})
